jQuery(function()
{
    /**
     * //////////////////////////////////////////////////////
     * //////////////////// Autocomplete ////////////////////
     * //////////////////////////////////////////////////////
     */
     $.ajaxSetup({
         headers: {
             'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
         }
     });

    /**
     * //////////////////////////////////////////////////////
     * Clients
     */

    //Search by client: open modal and ajax search
    if('#client_name') {
        $('#client_name').on('focus', function() {
            autocomplete_modal( $( this ) );
        });
    }

    if($( '#ajax_client' )) {
        $( '#ajax_client' ).autoComplete({
            minChars: 3,
            source: function( term, response ) {
                $( '#ajax_client' ).addClass('loading');
                $.getJSON(path + '/dashboard/ajax/clients', {
                    q: term,
                }, function( data ) {
                    response( data );
                });
                return false;
            },
            renderItem: function (item, search) {
                if($( '#ajax_client' ).removeClass('loading')) {
                    return autocomplete_link(item, search);
                }
            },
            onSelect: function(e, term, item) {
                return autocomplete_response( 'client', item.data('id'), item.data('title') );
            }
        });
    }

    /**
     * //////////////////////////////////////////////////////
     * Destination
     */

    //Search by destination: open modal and ajax search
    if('#destination_name') {
        $('#destination_name').on('focus', function() {
            autocomplete_modal( $( this ) );
        });
    }

    if($( '#ajax_destination' )) {
        $( '#ajax_destination' ).autoComplete({
            minChars: 3,
            source: function( term, response ) {
                $( '#ajax_destination' ).addClass('loading');
                $.getJSON(path + '/dashboard/ajax/destinations', {
                    q: term
                }, function( data ) {
                    response( data );
                });
                return false;
            },
            renderItem: function (item, search){
                if($( '#ajax_destination' ).removeClass('loading')) {
                    return autocomplete_link(item, search);
                }
            },
            onSelect: function(e, term, item){
                return autocomplete_response( 'destination', item.data('id'), item.data('title') );
            }
        });
    }

    /**
     * //////////////////////////////////////////////////////
     * ////////////////////// Sections //////////////////////
     * //////////////////////////////////////////////////////
     */

     /**
      * //////////////////////////////////////////////////////
      * Invoices
      */
     //Select Bank from a invoice
     $( '#company_id' ).on( 'change', function() {
         //
         var bank = $( '#bank_id' );
         var company = $( this ).val();
         //Only if the payment method is bank...
         if( company > 0 && $( '#invoice_payment_method' ).val() == 2 ) {
             //Remove the bank field
             bank.empty();
             bank.addClass('loading');
             //Get the data via AJAX
             $.get( path + '/dashboard/ajax/banks', { company: company },
             function( data ) {
                 //Only if there is data
                 if( data.length > 0 ) {
                     //First empty option field
                     bank.append( $( '<option>', { value: '', text : '' } ) );
                     //Built the select
                     $.each( data, function( index, element ) {
                         bank.append( $( '<option>', {
                             value: element.id,
                             text : element.name
                         }));
                     });
                     bank.prop( 'disabled', false ).prop( 'required', true ).removeClass('loading');
                 }
             });
         } else {
             bank.empty().prop( 'disabled', true ).prop( 'required', false );
         }
     });

     //Select payment method from a invoice
     $( '#invoice_payment_method' ).on( 'change', function() {
         //
         var value = $( this ).val();
         var bank = $( '#bank_id' );
         //Reset values
         bank.empty().prop( 'disabled', true ).prop( 'required', false );
         $( '#company_id' ).val( 0 );
     });

     //Select invoice type
     $( '#invoice_type' ).on( 'change', function() {
         //Reset values
         $( '#invoice_number' ).prop( 'disabled', true ).prop( 'required', false );
         //On select values
         var value = $( this ).val();
         if(value === '2') {
            $( '#invoice_number' ).prop( 'disabled', false ).prop( 'required', true );
         }
     });

     $('#autofill').on('click', function() {
         var url = '/dashboard/ajax/reservations/id';
         var id = $('#invoice_reservationsList').val();
         $('#invoice_address').addClass('loading');
         $.get( path + url, { id: (id.split(',')[0] ? id.split(',')[0] : id) },
         function( data ) {
             //Only if there is data
             if( data) {
                 var client_name = $('#invoice_client_name').val();
                 $('#invoice_client_name').val(client_name + ' - ' + data.passenger_name);
                 $('#invoice_zip').val(data.zip);
                 $('#invoice_nif').val(data.nif);
                 $('#invoice_city').val(data.city);
                 $('#invoice_address').val(data.address);
                 $('#invoice_state').val(data.state);
                 $('#invoice_country').val('');
                 $('#invoice_address').removeClass('loading');
             }
         });
     });

    /**
     * //////////////////////////////////////////////////////
     * Manteniments
     */
    if($( '#manteniment_type' )) {
        var manteniment = $( '#manteniment_type' );
        var id = manteniment.val();
        //
        manteniment.on( 'change' , function() {
            var that = $( this );
            var id = that.val();
            var title = that.find('option:selected').text();
            var description = that.find('option:selected').data('description');

            //Conditional title and description
            if( !$( '#manteniment_concept' ).val() ) {
                $( '#manteniment_concept' ).val( title );
            }

            if( !$( '#manteniment_description' ).val() ) {
                $( '#manteniment_description' ).val( description );
            }

            //Case gasoline
            check_manteniment( id );
        })
        check_manteniment( id );

        //
        function check_manteniment( value ) {
            if( value === '4' ) {
                $( '#manteniment_gasoline' ).prop( 'required', true ).next('span').addClass('input-addon-required');
            } else {
                $( '#manteniment_gasoline' ).prop( 'required', false ).next('span').removeClass('input-addon-required');
            }
            return false;
        }
    }

    /**
     * //////////////////////////////////////////////////////
     * Reservations
     */

     //Verify if the service is allready in the database
     function verifyReservation( client, company, destination, expire, car ) {
         $.ajax({
             url: path + '/dashboard/ajax/reservations/verify?client=' + client + '&company=' + company + '&destination=' + destination + '&expire=' + expire + '&car=' + car,
             success: function( data ) {
                 if( data.status == 'success' ) {
                     $( '#form-app-create' ).submit();
                 } else if( data.status == 'validation' ) {
                     $( '#modal-service-validate' ).modal( 'show' );
                 } else {
                     $( '#modal-service-confirm' ).modal( 'show' );
                 }
             }
         });
         //
         return false;
     }

    if( $( '#reservation_billing' ) ) {
        //Add / remove commission
        $( '#reservation_billing' ).on( 'change', function() {
            var commission = $( '#reservation_invoice_commission' );
            if( $( this ).find( 'option:selected' ).val() == 1 ) {
                commission
                    .attr( {disabled: false, required: true} )
                    .val( commission.data('billing') )
                    .next( 'span' )
                    .addClass( 'input-addon-required' )
                    .prev( 'label' )
                    .addClass( 'label-required' );
                commission
                    .parent( 'div' )
                    .prev( 'label.control-label' )
                    .addClass( 'label-required' );
            } else {
                commission
                    .attr( { disabled: true, required: false } )
                    .val('')
                    .next( 'span' )
                    .removeClass( 'input-addon-required' )
                    .prev( 'label' )
                    .removeClass( 'label-required' );
                commission
                    .parent( 'div' )
                    .prev( 'label.control-label' )
                    .removeClass( 'label-required' );
            }
        });

        //Get the expire value
        $( '#company_id, #client_id, #destination_id' ).on( 'change', function() {
            //Get the values
            getServiceExpire( $( '#client_id' ).val(), $( '#company_id' ).val(), $( '#destination_id' ).val() );
        });

        //Select car
        $( '#reservation_expire' ).on( 'change', function() {
            var service = $( this ).find( 'option:selected' ).data( 'service' );
            if( service ) {
                getServiceCar( $( '#client_id' ).val(), $( '#company_id' ).val(), $( '#destination_id' ).val() );
            }
        });

        //Select price and max passengers
        $( '#car_type_id' ).on( 'change', function() {
            //Get price
            var price = $( this ).find( 'option:selected' ).data( 'price' );
            if( price > 0 ) {
                $( '#reservation_invoice_price' ).val( price );
            }
            //Max pax
            var pax = $( this ).find( 'option:selected' ).data( 'pax' );
            $( '#reservation_passengers' ).attr( 'max', pax );
        });
        $( '#reservation_passengers' ).on( 'focus', function() {
            //Max pax
            var pax = $( '#car_type_id' ).find( 'option:selected' ).data( 'pax' );
            $( '#reservation_passengers' ).attr( 'max', pax );
        });

        //Add input fields
        $( '#add_field' ).on( 'click', function() { //on add input button click
            var input_value = $("[id^=field_concept]").length; //text box increment
            var container =
                '<div class="row" id="wrap_container_' + input_value + '">' +
                    '<div class="form-group col-md-7" rel="concept">' +
                        '<input type="text" name="field_concept[' + input_value + ']" id="field_concept[' + input_value + ']" class="form-control">' +
                    '</div>' +
                    '<div class="form-group col-md-2" rel="quantity">' +
                        '<input type="text" name="field_quantity[' + input_value + ']" id="field_quantity[' + input_value + ']" class="form-control number">' +
                    '</div>' +
                    '<div class="form-group col-md-2" rel="price">' +
                        '<div class="input-group">' +
                            '<input type="text" name="field_price[' + input_value + ']" id="field_price[' + input_value + ']" class="form-control number">' +
                            '<span class="input-group-addon">€</span>' +
                        '</div>' +
                    '</div>' +
                    '<button type="button" class="btn btn-danger btn-delete remove_field" data-target="wrap_container_' + input_value + '">X</button>' +
                '</div>';

            $( '#wrap_container' ).append( container ); //add input box
        });

        $( '.row' ).on( 'click', '.remove_field', function() { //user click on remove text
            $( '#' + $( this ).data( 'target' ) ).remove();
        });

        //Get the service expire
        function getServiceExpire( client, company, destination ) {
            //Default values
            var container = $( '#reservation_expire' );
            var url = '/dashboard/ajax/reservations/expires';
            //If fields all fill
            if( client > 0 && company > 0 && destination > 0 ) {
                container.addClass('loading');
                //Reset the fields
                resetReservationFields();
                //Get the data via AJAX
                $.get( path + url, { client: client, company: company, destination: destination },
                function( data ) {
                    //Only if there is data
                    if( data.length > 0 ) {
                        //First empty option field
                        container.append( $( '<option>', { value: '',text : ''} ) );
                        //Built the select
                        $.each( data, function( index, element ) {
                            container.append( $('<option>', {
                                value: element.service_expire,
                                text : element.service_expire,
                                'data-service': element.id
                            }));
                        });
                        //Remove disabled
                        removeDisabledFromContainer( container );
                        container.removeClass('loading');
                    }
                });
            }else{
                //Reset fields
                resetReservationFields();
            }
        };

        function getServiceCar( client, company, destination ) {
            //Default values
            var service = $( '#reservation_expire option:selected' ).data( 'service' );
            var url = '/dashboard/ajax/reservations/cars';
            var container = $( '#car_type_id' );
            container.addClass('loading');
            //If fields all fill
            if( client > 0 && company > 0 && destination > 0 && service > 0 ) {
                //Reset the fields
                resetReservationPrice();
                //Get the data via AJAX
                $.get( path + url, { service: service, client: client, company: company, destination: destination },
                function( data ) {
                    //Only if there is data
                    if( data.length > 0 ) {
                        //First empty option field
                        container.append( $( '<option>', { value: '',text : '' } ) );
                        //Built the select
                        $.each( data, function( index, element ) {
                            container.append( $('<option>', {
                                value: element.id,
                                text : element.name,
                                'data-price': element.price,
                                'data-pax': element.pax,
                            }));
                        });
                        //Add the service value
                        $('#service_id').val(service);
                        //Remove disabled
                        removeDisabledFromContainer( container );
                        container.removeClass('loading');
                    }
                });
            }else{
                resetReservationPrice();
            }
        };

        //Reset all configurate fields
        function resetReservationPrice() {
            $( '#reservation_invoice_price' ).val( '' );
            $( '#car_type_id' ).empty();
        }

        //Reset the price
        function resetReservationFields(){
            $( '#reservation_expire, #car_type_id' ).empty().attr({
                'disabled': true
            }).prev( 'label' ).removeClass( 'label-required' );
            $( '#reservation_invoice_price, #service_id' ).val( '' );
        }

        //Remove disabled element from container
        function removeDisabledFromContainer( container ) {
            container.attr({
                'disabled': false
            })
            .prev( 'label' )
            .addClass( 'label-required' );
        }
    }

    /**
     * //////////////////////////////////////////////////////
     * Services
     */

    //Open expired modal with all the alternative dates
    if('.open-modal-expire') {
        $('.open-modal-expire').on('click', function() {
            $( '#modal-expire' ).modal( 'show' );
        });
    }

    //Select a date from the modal
    if('.link-selected') {
        $('.link-selected').on('click', function() {
            $( '#modal-expire' ).modal( 'hide' );
            $( '#service_expire' ).val( $( this ).data('date') ).fadeOut(0, function(){
                $(this).addClass('modal-selected').fadeIn(500);
            });
        });
    }

    //This is only for the service create section
    //Using #form-credentials as a filter
    //We only use this scripts inside the service create section!!!!!
    $( '#button-store' ).on('click', function( event ) {
        event.preventDefault();
        verifyService( $( '#client_id' ).val(), $( '#company_id' ).val(), $( '#destination_id' ).val(), $( '#service_expire' ).val() );
    });

    $( '#button-service-confirm' ).on( 'click', function() {
        if( $( '#modal-service-confirm' ).modal( 'hide' ) ) {
            $( '#form-app-create' )[0].submit();
        }
    });

    //Select upgrade type
    if($( '#upgrade_type' )) {
        $( '#upgrade_type' ).on( 'change' , function() {
            //Reset all by default
            $( '.default-disabled' ).prop( 'disabled', true ).prop( 'required' , false ).val('');
            $( '[id^=container_]' ).addClass( 'hidden' );
            $( '#copy_services option:first-child' ).prop('selected', true);
            //Enable the input
            $( '#service_' + $(this).val() ).prop( 'disabled' , false ).prop( 'required' , true ).focus();
            //Enable container
            $( '#container_' + $(this).val() ).removeClass( 'hidden' );
        });
    }

    //Verify if the service is allready in the database
    function verifyService( client, company, destination, date ) {
        $.ajax({
            url: path + '/dashboard/ajax/services/verify?client=' + client + '&company=' + company + '&destination=' + destination + '&date=' + date,
            success: function( data ) {
                if( data.status == 'success' ) {
                    $( '#form-app-create' ).submit();
                } else if( data.status == 'validation' ) {
                    $( '#modal-service-validate' ).modal( 'show' );
                } else {
                    $( '#modal-service-confirm' ).modal( 'show' );
                }
            }
        });
        //
        return false;
    }

    /**
     * //////////////////////////////////////////////////////
     * Transfers
     */

    //Select Bank from a invoice
    $( '#transfer_origen' ).on( 'change', function() {
        //
        var destination = $( '#transfer_destination' );
        //Only if the payment method is bank...
        //Remove the bank field
        destination.addClass('loading').find('option').remove();
        //Get the data via AJAX
        $.get( path + '/dashboard/ajax/transfers/destinations', { origen: $(this).val() },
        function( data ) {
            //Only if there is data
            if( data.length > 0 ) {
                //First empty option field
                destination.append( $( '<option>', { value: '', text : '' } ) );
                //Built the select
                $.each( data, function( index, element ) {
                    destination.append( $( '<option>', {
                        value: element.id,
                        text : element.name
                    }));
                });
            }
            destination.removeClass('loading');
        });

    });
});

/**
 * //////////////////////////////////////////////////////
 * ////////////////////// Functions //////////////////////
 * //////////////////////////////////////////////////////
 */
//Generate the list of prices to be showed in a bootstrap modal
function getPrices( service, company, client, destination, expire ) {
    //Capture the modal
    var target_modal = $( '#modal-list-prices' );
    //Open the modal
    target_modal.find( '.modal-body' ).html( '<div class="text-center"><img src="../../../../img/loading.gif"></div>' );
    target_modal.modal('toggle');
    //Get the prices!!!
    $.ajax({
        url: path + '/dashboard/ajax/services/prices?id=' + service + 'sync=' + new Date().getTime(),
        success: function( data ) {
            //Set variables
            var html; var total = 0;
            //
            //Generate the html
            html = '<div id="service-prices-header">';
                html += '<h2>' + lng_company + ': <span>' + company + '</span></h2>';
                html += '<h2>' + lng_client + ': <span>' + client + '</span></h2>';
                html += '<h2>' + lng_destination + ': <span>' + destination + '</span></h2>';
                html += '<h2>' + lng_expire + ': <span>' + expire + '</span></h2>';
            html += '</div><hr>';
            //
            //Each price for the service
            html += '<div class="service-prices-body">';
                $.each( data, function( index, element ){
                    if( element.car_type ){
                        html += '<div class="service-prices-item">' + element.car_type.car_type_name + ': <span>' + element.service_car_price + '€</span></div>';
                        total++;
                    }
                });
                //
                //If no result... using the datatables variable for no results....
                if( total === 0 ) {
                    html += '<div class="text-center">' + lng_noResults + '</div>';
                }
            //
            html += '</div>';
            //
            //Add the html
            target_modal.find( '.modal-body' ).html( html );
        }
    });
};
